














































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({})
export default class Login extends Vue {
  public clientName: string = '';
  public username: string = '';
  public password: string = '';

  public errorMessage: string = '';
  public redirectUri: string = '';

  public mounted () {
    this.getClientName()
      .then((text) => {
        this.clientName = text;
      });
  }

  public get targetName () {
    return this.$t(`application.${this.target}.name`);
  }

  public get target (): string {
    return this.$route.query.target as string;
  }

  public getClientName (): Promise<string> {
    return axios.get(
      '/api/oauth/client-name?client_id=' + this.$route.query.client_id
    )
      .then((response) => {
        return response.data;
      });
  }

  public login (): void {
    axios.post(
      '/api/oauth/authorize?response_mode=json',
      {
        response_type: this.$route.query.response_type,
        grant_type: 'password',
        username: this.username,
        password: this.password,
        state: this.$route.query.state,
        redirect_uri: this.$route.query.redirect_uri,
        client_id: this.$route.query.client_id,
        scope: this.$route.query.scope
      }
    )
      .then((response) => {
        this.errorMessage = '';
        console.log(response);
        if (response.data.redirect_uri) {
          this.redirectUri = response.data.redirect_uri;
          window.location.href = response.data.redirect_uri;
        }
      })
      .catch((err) => {
        console.error(err);
        console.error({ ...err });
        this.redirectUri = '';
        this.errorMessage = err?.response?.data?.message || err;
      });
  }
}
